import oauthSettings from './vsko-oauth-configuration-stripped.json';

export const settings = {
  angularModuleName: "newsletterpreview-ui",
  projectName: "newsletterpreview-ui",
  demo: true,
  newsletterSecurity: {
    ability: "execute",
    componentHref: "/security/components/newsletter-api",
    resource: "/newslettersender/sendwithjob",
  },
  release: "dnieuwsbrief-2__main__2024-06-17T10:04:09.327Z",
  projectDescription: "Newsletter preview of Katholiek Onderwijs Vlaanderen.",
  googleAnalytics: {
    enabled: false,
    token: "UA-12345678-90",
  },
  apisAndUrls: {
    cachedApi: "https://cached-api.katholiekonderwijs.vlaanderen",
    api: "https://api.katholiekonderwijs.vlaanderen",
    contentApi: "https://dcontent-api.katholiekonderwijs.vlaanderen",
    newsletterApi: "https://dnewsletter-api.katholiekonderwijs.vlaanderen",
    newsletter: "https://dnewsletter-preview.katholiekonderwijs.vlaanderen/newslettersender",
    security: "https://beveiliging-nodejs.katholiekonderwijs.vlaanderen",
  },
  logging: {
    sentry: {
      enabled: false,
      url: "https://something@sentry.io/1234567",
    },
    redux: true,
  },
  trustedOrigins: ["*"],
  oauth: oauthSettings,
};